export type ConnectionStatus = "OPEN" | "CLOSED" | "ERROR";

export type VsCodeState = {
    connectionStatus: ConnectionStatus;
};

export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";

export interface SetConnectionStatusAction {
    type: typeof SET_CONNECTION_STATUS;
    status: ConnectionStatus;
}
export type VsCodeActionTypes = SetConnectionStatusAction;


export type RequestTemplateServerToClientMessage = { type: "REQUEST_TEMPLATE"; };
export type UpdateTemplateServerToClientMessage = { type: "UPDATE_TEMPLATE"; template: string; css: string; };
export type SaveTemplateServerToClientMessage = { type: "SAVE_TEMPLATE", template: string, css: string };
export type ServerToClientMessage = RequestTemplateServerToClientMessage | UpdateTemplateServerToClientMessage | SaveTemplateServerToClientMessage;


export type LoadTemplateClientToServerMessage = {
    type: "LOAD_TEMPLATE";
    template: string;
    css: string;
};
export type LoadComponentDocumentationClientToServerMessage = {
    type: "LOAD_COMPONENT_DOCUMENTATION",
    components: Record<string, any>
};
export type LoadCanvasDataClientToServerMessage = {
    type: "LOAD_CANVAS_DATA",
    canvasData: Record<string, any>
};
export type LoadCssClassesClientToServerMessage = {
    type: "LOAD_CSS_CLASSES",
    classNames: string[]
};

export type ClientToServerMessage = LoadTemplateClientToServerMessage | LoadComponentDocumentationClientToServerMessage | LoadCanvasDataClientToServerMessage | LoadCssClassesClientToServerMessage;