import * as api from "../../shared/api-client"

export interface BreadcrumbItem {
    id?: string;
    title?: string;
    url?: string;
    children?: BreadcrumbItem[];
}

export type ErrorType = "NotFound" | "Connectivity" | "Generic";

export interface AppError {
    type: ErrorType;
    message: string;
    details: string; // Markdown containing message details...
}

export interface AppErrors {
    global?: AppError; // Fatal error during app load - don't attempt to render anything other than the error page...
    page?: AppError; // Page-specific error - render the error page in the main content area...
}

export interface AppUser {
    name: string;
    roles: string[];
    isDeveloper: boolean;
}

export interface AppState {
    loading: boolean;
    menuItems: api.MenuItemDisplayModel[];
    menuIsExpanded: boolean;
    breadcrumbStack: BreadcrumbItem[];
    errors: AppErrors;
    user: AppUser;
}

export const LOAD_APP = "LOAD_APP";
export const SHOW_APP = "SHOW_APP";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const SET_MENU_IS_EXPANDED = "SET_MENU_IS_EXPANDED";
export const SET_BREADCRUMB_STACK = "SET_BREADCRUMB_STACK";
export const SET_GLOBAL_ERROR = "SET_GLOBAL_ERROR";
export const SET_PAGE_ERROR = "SET_PAGE_ERROR";

interface LoadAppAction {
    type: typeof LOAD_APP;
    user: AppUser;
}

interface ShowAppAction {
    type: typeof SHOW_APP;
    menuItems: api.MenuItemDisplayModel[];
}

interface ToggleMenuAction {
    type: typeof TOGGLE_MENU;
}

interface SetBreadcrumbStackAction {
    type: typeof SET_BREADCRUMB_STACK;
    breadcrumbStack: BreadcrumbItem[];
}

interface SetMenuIsExpandedAction {
    type: typeof SET_MENU_IS_EXPANDED;
    value: boolean;
}

interface SetGlobalErrorAction {
    type: typeof SET_GLOBAL_ERROR;
    error: AppError;
}

interface SetPageErrorAction {
    type: typeof SET_PAGE_ERROR;
    error: AppError;
}

export type AppActionTypes = LoadAppAction | ShowAppAction | ToggleMenuAction | SetBreadcrumbStackAction | SetMenuIsExpandedAction | SetGlobalErrorAction | SetPageErrorAction;