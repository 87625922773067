import React from "react";
import "./SidenavMiniMap.scss";
import Canvas from "../Canvas/Canvas";
import { Slide, StorylineState } from "../../store/storyline/types";
import { navigateTo, goToXYZ } from "../../store/storyline/actions";
import { CircularProgress, ConditionalTreeItem } from "../../shared/components";
import { Book, Chapter, VDT } from "../../shared/components/icons";
import { TreeView, TreeItem } from "@mui/lab";
import { RootState } from "../../store";
import { connect, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import * as _ from "lodash";
import { TriangleDown, TriangleRight } from "../../shared/components/icons";
import { AppError } from "../../store/app/types";

const MiniMapFramePreview = React.memo((props: { slide: Slide, ix: number, iy: number, fi: number }) => {
    const { slide, ix, iy, fi } = props;
    const template = slide?.template;
    const frameData = slide?.frames?.[fi];

    if (!template || !frameData) return null;

    return (
        <div className="minimap-hover-tooltip-content">
            <div className="hover-tooltip-slide-title">{frameData?.title}</div>
            <div className="slide">
                <Canvas key={`mini-map-slide-hover-preview-${ix}-${iy}-${fi}`} template={template} frameData={frameData} staticPlot={true} />
            </div>
        </div>
    );
});

interface MiniMapProps {
    navigateTo: typeof navigateTo,
    goToXYZ: typeof goToXYZ,
    pageError: AppError
}

function SIDENAV_MINIMAP(props: MiniMapProps) {
    const { goToXYZ, pageError } = props;
    const { slides, xIndex, yIndex, frameIndex, loading } = useSelector((state: RootState) => state.storyline ?? {});
    const [expandedNodes, setExpandedNodes] = React.useState([]);
    const hideMiniMap = slides?.length === 1 && slides[0]?.length === 1 && slides[0][0]?.frames?.length === 1;

    React.useEffect(() => {
        const allIds = _.flatten(slides.map((c, ci) => [`node-${ci}`, ...c.map((p, pi) => `node-${ci}-${pi}`)]));
        setExpandedNodes(allIds);
    }, [slides]);

    const onNodeToggle = (event: any, nodeIds: string[]) => {
        setExpandedNodes(nodeIds);
    }

    // (Re)initialize the tooltip handler when the storyline is updated (data sources changed, etc.)...
    React.useEffect(() => {
        setTimeout(() => ReactTooltip.rebuild(), 1000);
    }, [slides]);

    const getTooltipContent = React.useCallback((dataString) => {
        if (!dataString) return null;
        const [ix, iy, fi] = dataString.split("|").map(a => parseInt(a));
        return <MiniMapFramePreview slide={slides?.[ix]?.[iy]} ix={ix} iy={iy} fi={fi} />;
    }, [slides]);

    if (pageError == null && loading) {
        return <div className="minimap-loading"><CircularProgress /></div>;
    }

    return (
        hideMiniMap ? null :
        <div className="minimap">
            <ReactTooltip id="slide-preview" type="light" effect="solid" place="right" clickable={false} className="tooltip-primary minimap-hover-tooltip" getContent={getTooltipContent} />
            <TreeView
                expanded={expandedNodes}
                onNodeToggle={onNodeToggle}
                disableSelection={true}
                defaultCollapseIcon={<TriangleDown size="small" />}
                defaultExpandIcon={<TriangleRight size="small" />}
            >
                {
                    slides.map((chapter, chapterIndex) => <ConditionalTreeItem key={`node-${chapterIndex}`} show={!chapter?.[0]?.canHideChapter || chapter.length > 1} nodeId={`node-${chapterIndex}`} className={xIndex === chapterIndex ? "active" : ""} label={
                        <div className="menu-item-label">
                            <Book size="small" />
                            <span>
                                {
                                    chapter?.[0]?.chapterTitle || `Chapter ${chapterIndex + 1}`
                                }
                            </span>
                        </div>
                    }>
                        {chapter.map((page, pageIndex) => <ConditionalTreeItem key={`node-${chapterIndex}-${pageIndex}`} show={!page.canHidePage || page.frames.length > 1} nodeId={`node-${chapterIndex}-${pageIndex}`} className={xIndex === chapterIndex && yIndex === pageIndex ? "active" : ""} label={
                            <div className="menu-item-label">
                                <Chapter size="small" />
                                <span>
                                    {
                                        page.pageTitle || `Page ${pageIndex + 1}`
                                    }
                                </span>
                            </div>
                        }>
                            {
                                page.frames.map((frame, _frameIndex) =>
                                    <TreeItem key={`node-${chapterIndex}-${pageIndex}-${_frameIndex}`} nodeId={`node-${chapterIndex}-${pageIndex}-${_frameIndex}`} className={xIndex === chapterIndex && yIndex === pageIndex && frameIndex === _frameIndex ? "active tooltip-target" : "tooltip-target"} label={
                                        <div className="menu-item-label">
                                            <VDT size="small" />
                                            <span>{frame["title"]}</span>
                                        </div>
                                    }
                                        data-for="slide-preview"
                                        data-tip={`${chapterIndex}|${pageIndex}|${_frameIndex}`}
                                        onClick={() => goToXYZ(chapterIndex, pageIndex, _frameIndex)}
                                    >
                                    </TreeItem>)}
                        </ConditionalTreeItem>)}
                    </ConditionalTreeItem>)
                }
            </TreeView>
        </div>
    );
}

const SidenavMiniMap = React.memo(SIDENAV_MINIMAP);

export default connect(
    (state: RootState) => ({
        pageError: state.app.errors.page
    }),
    { navigateTo, goToXYZ })(SidenavMiniMap);