import React from "react";
import "./EditUser.scss";
import * as api from "../../shared/api-client";
import * as _ from "lodash";
import { Button, Dialog, DialogContent, TextField, DialogActions, Select, MenuItem, Chip, DialogTitleWithCloseButton, InlineInfoTooltip, InfoAlert, KeyValueEditor } from "../../shared/components";
import { Controller, useForm } from 'react-hook-form';
import { object, string, array } from 'yup';
import { yupResolver } from "@hookform/resolvers";
import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";

interface EditUserProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    user?: api.UserDisplayModel,
    showSuccess: typeof showSuccess,
    showError: typeof showError,
    onSuccess: () => void
}

function EditUser(props: EditUserProps) {
    const schema = object().shape({
        name: string()
            .required("This field is required."),
        emailAddress: string()
            .required("This field is required."),
        roleIds: array(),
        metadataEntries: array()
    });
    const { open, setOpen, user, showSuccess, showError, onSuccess } = props;
    const { register, handleSubmit, errors, reset, setError, control } = useForm({ resolver: yupResolver(schema) });
    const [rolesLookup, setRolesLookup] = React.useState([]);
    const [roleIds, setRoleIds] = React.useState([]);
    const [formErrors, setFormErrors] = React.useState(null);

    React.useEffect(() => {
        new api.LookupsClient().getRoles()
            .then(result => setRolesLookup(result));
    }, []);

    React.useEffect(() => {
        setRoleIds(user ? _.map(user.roles, r => r.id) : []);
        reset(user || {});
    }, [user]);

    const closeDialog = () => {
        setOpen(false);
    }

    const attachFormErrors = (errorDictionary: object) => {
        _.forOwn(errorDictionary, (value, key) => {
            if (key === "non_field_errors") {
                setFormErrors(value[0]);
            }
            else {
                setError(key, { type: "server", message: value[0] });
            }
        });
    }

    const save = (formModel) => {
        const model = {
            ...formModel,
            roleIds
        };

        // Bulk create...
        if (!user && (formModel.name.includes(",") || formModel.emailAddress.includes(","))) {
            let names = formModel.name.split(",").map(a => a.trim()) as string[];
            let emailAddresses = formModel.emailAddress.split(",").map(a => a.trim()) as string[];

            if (names.length !== emailAddresses.length) {
                setError("name", { type: "validation", message: "Length of names and email addresses do not match.  Please double-check both." });
                return;
            }

            let apiCalls = _.zip(names, emailAddresses).map(async ([name, emailAddress], index) => {
                return await new api.UsersClient()
                    .create({
                        name,
                        emailAddress,
                        roleIds
                    })
                    .then(() => showSuccess(`${name} has been created successfully.`))
                    .catch((error: api.ApiException) => showError(`${name} was not created successfully - ${error.response}`));
            });

            Promise.all(apiCalls).then(() => {
                closeDialog();
                onSuccess();
            });

            return;
        }

        const apiCall = user ?
            new api.UsersClient().update(user.id, model) :
            new api.UsersClient().create(model);

        (apiCall as Promise<any>)
            .then(() => {
                closeDialog();
                showSuccess(`User has been ${user ? "updated" : "created"} successfully.`);
                onSuccess();
            })
            .catch((error: api.ApiException) => {
                if (error.status === 400) {
                    attachFormErrors(JSON.parse(error.response || "{}"));
                }
                else {
                    showError(error.response);
                }
            });
    };

    const getRole = (id) => {
        return _.find(rolesLookup, r => r.id === id);
    }

    return (
        <Dialog
            id="edit-user-dialog"
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit(save)}>
                <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                    {`${user ? "Edit " + user.name : "Create User"}`}
                </DialogTitleWithCloseButton>
                <DialogContent>
                    {
                        !user &&
                        <InfoAlert className="margin-bottom-2">
                            A welcome email will be sent to this user, containing instructions for login/registration.
                        </InfoAlert>
                    }
                    <div className="field-header">Name</div>
                    <TextField
                        name="name"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />
                    <div className="field-header">Email Address</div>
                    <TextField
                        name="emailAddress"
                        error={!!errors.emailAddress}
                        helperText={errors.emailAddress?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                    />
                    <div className="field-header">Roles</div>
                    <Select
                        className="margin-bottom-2"
                        multiple
                        error={!!errors.roleIds}
                        value={roleIds}
                        onChange={(event) => setRoleIds(event.target.value as string[])}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            id: 'roles',
                        }}
                        renderValue={(selected) => (
                            <div>
                                {(selected as string[]).map((value) => (
                                    <Chip key={value} label={getRole(value)?.name} color="primary" className="multiselect-chip" />
                                ))}
                            </div>
                        )}
                    >
                        {rolesLookup.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                                {role.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <div className="field-header">Metadata
                        <InlineInfoTooltip
                            text="Any additional information about the user can be captured here.  This will be available to the application and can be utilized for custom logic/bindings in storylines."
                        />
                    </div>
                    <Controller
                        name="metadataEntries"
                        control={control}
                        render={p => (
                            <KeyValueEditor
                                keyName="field"
                                updateEntries={p.onChange}
                                entries={p.value}
                            />
                        )}
                    />
                    {
                        formErrors &&
                        <div className="form-errors">
                            {formErrors}
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        {`${user?.id ? "Update" : "Create"} User`}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default connect(
    null,
    { showSuccess, showError })(EditUser);