import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";
import {
    Routes,
    Route,
    Navigate,
    Outlet
} from "react-router-dom";
import Sidenav, { MenuItem } from "../shared/components/Sidenav";
import { ErrorContainer } from "../shared/pages/ErrorContainer";
import { RootState } from "../store";
import { setMenuIsExpanded } from "../store/app/actions";
import { loadCanvas, loadStoryline } from "../store/storyline/actions";
import { RenderStoryline, useViewerState } from "../viewer";
import SidenavMiniMap from "../viewer/SidenavMiniMap/SidenavMiniMap";
import Users from "./Users/Users";

export const menuItems: MenuItem[] = [{
    id: "administration",
    title: "Administration",
    children: [
        {
            id: "users",
            title: "Users",
            description: "Manage users",
            icon: "person",
            url: "/admin/users"
        },
    ]
}];

function Admin(props) {
    const { parameterValues, loadStoryline, loadCanvas, setMenuIsExpanded, menuItems } = props;
    const { visibilityClasses, ensureCorrectStorylineIsLoaded } = useViewerState(parameterValues, loadStoryline, loadCanvas, setMenuIsExpanded);

    return (
        <Routes>
            <Route index element={<Navigate to="/admin/users" />} />
            <Route
                path={`storylines/:id`}
                element={
                    <div className="app-layout admin-page">
                        <Sidenav menuItems={menuItems} activeItemContent={<SidenavMiniMap />} className={clsx(visibilityClasses)} />
                        <div className={clsx("content", visibilityClasses)}>
                            <RenderStoryline loader={ensureCorrectStorylineIsLoaded(loadStoryline)} />
                        </div>
                    </div>

                }
            />
            <Route
                path={`canvases/:id`}
                element={
                    <div className="app-layout admin-page">
                        <Sidenav menuItems={menuItems} activeItemContent={<SidenavMiniMap />} className={clsx(visibilityClasses)} />
                        <div className={clsx("content", visibilityClasses)}>
                            <RenderStoryline loader={ensureCorrectStorylineIsLoaded(loadCanvas)} />
                        </div>
                    </div>
                }
            />
            <Route
                path="/"
                element={
                    <div className="app-layout admin-page">
                        <Sidenav menuItems={menuItems} />
                        <div className="content">
                            <ErrorContainer error={props.pageError} includeNavBar />
                            <Outlet />
                        </div>
                    </div>
                }
            >
                <Route path="users" element={<Users />} />
            </Route>
        </Routes>
    );
}

export default connect(
    (state: RootState) => ({
        loading: state.app.loading,
        parameterValues: state.storyline.parameterValues,
        storylineId: state.storyline.id,
        pageError: state.app.errors.page,
    }),
    { loadStoryline: loadStoryline as any, loadCanvas: loadCanvas as any, setMenuIsExpanded })(Admin);