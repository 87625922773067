import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import * as _ from "lodash";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import { useStaticPlot } from "../../../shared/providers/StaticPlotProvider";
import { MapWithPathSupport } from "../../../shared/utilities";

interface CanvasEventHandlerProps {
    onEnter: () => void;
    onLeave: () => void;
    parameterValues: Map<string, any>;
    currentFrame: Object;
    datasourcesInFlight: Set<string>;
    onParameterValuesChanged: () => void;
    onParameterValueChanged: (parameterName: string, newValue: any, oldValue: any) => void;
    onFrameDataChanged: () => void;
    onFieldValueChanged: (fieldName: string, newValue: any, oldValue: any) => void;
    onDatasourceRefreshStart: (datasourceName: string) => void;
    onDatasourceRefreshEnd: (datasourceName: string) => void;
}

function _CanvasEventHandler(props: CanvasEventHandlerProps) {
    const { onEnter, onLeave, parameterValues, currentFrame, datasourcesInFlight, onParameterValuesChanged, onParameterValueChanged, onFrameDataChanged, onFieldValueChanged, onDatasourceRefreshStart, onDatasourceRefreshEnd } = props;

    const _lastParameterValues = React.useRef(_.cloneDeep(parameterValues ?? new MapWithPathSupport()));
    const _lastFrameData = React.useRef(_.cloneDeep(currentFrame));
    const _lastDatasourcesInFlight = React.useRef([]);

    const lastParameterValues = _lastParameterValues.current;
    const lastFrameData = _lastFrameData.current;
    const lastDatasourcesInFlight = _lastDatasourcesInFlight.current;

    React.useEffect(() => {
        onEnter && onEnter();
        return onLeave;
    }, []);

    React.useEffect(() => {
        if (!parameterValues) return;
        if (!onParameterValuesChanged && !onParameterValueChanged) return;

        if (!_.isEqual(lastParameterValues, parameterValues)) {
            onParameterValuesChanged && onParameterValuesChanged();

            for (let key of new Set([...lastParameterValues.keys(), ...parameterValues.keys()])) {
                const newValue = parameterValues.get(key);

                if (!lastParameterValues.has(key)) {
                    onParameterValueChanged?.(key, newValue, null);
                } else {
                    const oldValue = lastParameterValues.get(key);

                    if (!_.isEqual(oldValue, newValue)) {
                        onParameterValueChanged?.(key, newValue, oldValue);
                    }
                }

                // Mutate the last parameter values to reflect the current state...
                if (parameterValues.has(key)) {
                    lastParameterValues.set(key, newValue);
                } else {
                    lastParameterValues.delete(key);
                }
            }
        }
    }, [parameterValues]);

    React.useEffect(() => {
        if (!currentFrame) return;
        if (!onFrameDataChanged && !onFieldValueChanged) return;

        if (!_.isEqual(lastFrameData, currentFrame)) {
            onFrameDataChanged && onFrameDataChanged();

            for (let key of new Set([...Object.keys(lastFrameData), ...Object.keys(currentFrame)])) {
                const newValue = currentFrame[key];

                if (!Object.hasOwn(lastFrameData, key)) {
                    onFieldValueChanged?.(key, newValue, null);
                } else {
                    const oldValue = lastFrameData[key];

                    if (!_.isEqual(oldValue, newValue)) {
                        onFieldValueChanged?.(key, newValue, oldValue);
                    }
                }

                // Mutate the last frame data to reflect the current state...
                if (Object.hasOwn(currentFrame, key)) {
                    lastFrameData[key] = currentFrame[key];
                }
                else {
                    delete lastFrameData[key];
                }
            }
        }
    }, [currentFrame]);

    React.useEffect(() => {
        const datasourcesInFlightArray = Array.from(datasourcesInFlight.values());

        const requestsAdded = _.difference(Array.from(datasourcesInFlight.values()), lastDatasourcesInFlight);
        const requestsRemoved = _.difference(lastDatasourcesInFlight, datasourcesInFlightArray);

        requestsAdded.forEach(r => {
            onDatasourceRefreshStart?.(r);
            lastDatasourcesInFlight.push(r);
        });
        requestsRemoved.forEach(r => {
            onDatasourceRefreshEnd?.(r);
            _lastDatasourcesInFlight.current = lastDatasourcesInFlight.filter(x => x !== r);
        });
    }, [datasourcesInFlight]);

    return null;
}

const RawCanvasEventHandler = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues,
        currentFrame: state.storyline.currentFrame.frame,
        datasourcesInFlight: state.storyline.datasourcesInFlight
    }),
    null)(_CanvasEventHandler);

// Thin wrapper around CanvasEventHandler which prevents rendering when `staticPlot` is false.
const CanvasEventHandler = (props: CanvasEventHandlerProps) => {
    const staticPlot = useStaticPlot();
    if (staticPlot) return;

    return <RawCanvasEventHandler {...props} />;
}

(CanvasEventHandler as DocumentedComponent).metadata = {
    description: `The \`CanvasEventHandler\` component can be used to add custom logic to the lifecycle events of a canvas.  Arbitrary Javascript code can be executed inside these event handlers in order to add novel behavior to the canvas.  Some examples of possible custom behavior:

* Redirecting to specific storylines based on User Metadata
* Populating parameter values based on User Metadata
* Starting/stopping animations
* Starting/stopping narration`,
    isSelfClosing: true,
    attributes: [
        { name: `onEnter`, type: "function", template: `onEnter={() => {\n\t$1\n}}`, description: `Callback that is executed when a user navigates to the canvas.` },
        { name: `onLeave`, type: "function", template: `onLeave={() => {\n\t$1\n}}`, description: `Callback that is executed when a user navigates away from the canvas.` },
        { name: `onParameterValueChanged`, type: "function", template: `onParameterValueChanged={(parameterName, newValue, oldValue) => {\n\t$1\n}}`, description: `Callback that is executed when a parameter value has changed.  The \`parameterName\` input parameter can be used to determine which parameter was changed, whereas the \`newValue\` parameter will contain the updated value and \`oldValue\` will contain the previous value.` },
        { name: `onParameterValuesChanged`, type: "function", template: `onParameterValuesChanged={() => {\n\t$1\n}}`, description: `Callback that is executed when the parameter values have been updated.  The function can use \`this.parameterValues\` to access the parameter values map.` },
        { name: `onFrameDataChanged`, type: "function", template: `onFrameDataChanged={() => {\n\t$1\n}}`, description: `Callback that is executed when the frame data for the canvas has changed.` },
        { name: `onFieldValueChanged`, type: "function", template: `onFieldValueChanged={(fieldName, newValue, oldValue) => {\n\t$1\n}}`, description: `Callback that is executed when a field in the frame data has changed.  The \`fieldName\` input parameter can be used to determine which field has changed, whereas the \`newValue\` parameter will contain the updated value and \`oldValue\` will contain the previous value.` },
        { name: `onDatasourceRefreshStart`, type: "function", template: `onDatasourceRefreshStart={(datasourceName) => {\n\t$1\n}}`, description: `Callback that is executed when a datasource is being refreshed.  The network request should be in-flight at this stage.  The \`datasourcesInFlight\` field (available to the template bindings) should be preferred above manual parameter value management via this callback and its response counterpart.` },
        { name: `onDatasourceRefreshEnd`, type: "function", template: `onDatasourceRefreshEnd={(datasourceName) => {\n\t$1\n}}`, description: `Callback that is executed when a datasource refresh response has been received.  Useful in cases where dependent datasources need to be refreshed after some persistence action has occurred.  A timestamp or version response from the datasource can also be used to track this, but this callback may be cleaner/simpler in some cases.` },
    ]
};

export default CanvasEventHandler;