import React from "react";
import "./Storyline.scss";
import Canvas from "../Canvas/Canvas";
import StorylineActions from "../actions";
import { StorylineState } from "../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { navigateTo, goToXYZ, goToID, updateParameterValue } from "../../store/storyline/actions";
import CanvasDebugger from "../../developer/CanvasDebugger/CanvasDebugger";
import { AppInsightsViewTracker, Loader, NavBar, PopoutWindow } from "../../shared/components";
import { useAuth0 } from "../../auth/AuthContext";
import { ROLES } from "../../auth/types";
import { AppError, BreadcrumbItem } from "../../store/app/types";
import { AppThemeProvider } from "../../shared/providers/AppThemeProvider";
import { ErrorContainer } from "../../shared/pages/ErrorContainer";

const storylineActions = <StorylineActions />;

interface StorylineProps {
    id: string,
    storyline: StorylineState,
    pageError?: AppError,
    navigateTo: typeof navigateTo,
    goToXYZ: typeof goToXYZ,
    goToID: typeof goToID,
    updateParameterValue: typeof updateParameterValue,
    breadcrumbStack: BreadcrumbItem[]
}

export function Storyline(props: StorylineProps) {
    const { storyline, pageError, navigateTo, updateParameterValue, breadcrumbStack } = props;
    const [devWindowIsOpen, setDevWindowIsOpen] = React.useState(false);
    const { roles, userMetadata } = useAuth0();
    const toggleDevWindow = () => {
        setDevWindowIsOpen(!devWindowIsOpen);
    }

    // Promote current storyline state and navigateTo action to global state in order to allow access via the storyline exporter...
    React.useEffect(() => {
        window["storyline"] = storyline;
        window["navigateTo"] = navigateTo;
        window["updateParameterValue"] = updateParameterValue;
    }, [storyline, navigateTo, updateParameterValue]);

    React.useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            switch (e.key) {
                case "F2":
                case "F9":
                    if (roles?.includes?.(ROLES.DEVELOPER)) {
                        toggleDevWindow();
                    }
                    else {
                        console.log("Current user does not have access to the Canvas Debug window.");
                    }
                    break;
            }

            // We only want to handle global keyboard events here - ignore those from input fields, dropdowns, sliders, etc...
            const target = e.target as Element;
            if (target?.tagName?.toLowerCase() !== "body") return;

            switch (e.key) {
                case "ArrowUp":
                    if (e.ctrlKey) {
                        navigateTo("PREVIOUS_SECTION");
                    }
                    else {
                        navigateTo("PREVIOUS_ITEM");
                    }
                    break;
                case "ArrowDown":
                    if (e.ctrlKey) {
                        navigateTo("NEXT_SECTION");
                    }
                    else {
                        navigateTo("NEXT_ITEM");
                    }
                    break;
                case "ArrowLeft":
                    navigateTo("PREVIOUS_CHAPTER");
                    break;
                case "ArrowRight":
                    navigateTo("NEXT_CHAPTER");
                    break;
            }
        }

        document.addEventListener("keyup", handleKeyUp);
        return () => document.removeEventListener("keyup", handleKeyUp);
    }, [navigateTo]);

    return (
        <>
            <NavBar title={storyline?.currentFrame?.frame?.title} breadcrumbStack={breadcrumbStack} actions={storylineActions}>
            </NavBar>
            <ErrorContainer error={pageError}>
                {
                    storyline.loading ?
                        <div className="home-content"><Loader title="Loading Storyline..." description="This shouldn't take long." /></div> :

                        <div className="storyline">
                            <div className="main-canvas">
                                <AppInsightsViewTracker key={`storyline-tracker-${storyline?.id}`} componentType="storyline" title={storyline?.name} metadata={{ storylineName: storyline?.name, userMetadata }}>
                                    <AppInsightsViewTracker key={`canvas-tracker-${storyline?.xIndex}-${storyline?.yIndex}`} componentType="canvas" title={storyline?.currentFrame?.frame?.title} metadata={{ canvasName: storyline?.slides?.[storyline.xIndex]?.[storyline.yIndex]?.name, storylineName: storyline?.name, userMetadata }}>
                                        <Canvas template={storyline?.currentFrame?.template} frameData={storyline?.currentFrame?.frame} navigateTo={props.goToID} staticPlot={false} />
                                    </AppInsightsViewTracker>
                                </AppInsightsViewTracker>
                            </div>
                        </div>
                }
            </ErrorContainer>

            {devWindowIsOpen &&
                <>
                    <PopoutWindow onUnload={toggleDevWindow} url="" title="Canvas Debugger" features={{ width: 1024, height: 768 }}>
                        <AppThemeProvider>
                            <CanvasDebugger slide={storyline?.slides?.[storyline.xIndex]?.[storyline.yIndex]} frameData={storyline?.currentFrame?.frame} />
                        </AppThemeProvider>
                    </PopoutWindow>
                </>
            }
        </>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline,
        breadcrumbStack: state.app.breadcrumbStack,
        pageError: state.app.errors.page,
    }),
    { navigateTo, goToXYZ, goToID, updateParameterValue: updateParameterValue as any })(Storyline);
