import { useAuth0 } from "../../auth/AuthContext";

interface RequiresRoleProps {
    roleName: string;
    children: any;
}

function RequiresRole(props: RequiresRoleProps) {
    const { roleName, children } = props;
    const { roles } = useAuth0();

    return (
        roles?.includes?.(roleName) && children
    );
}

export default RequiresRole;