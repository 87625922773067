import React from "react";
import "./Users.scss";
import { Card, Container, Button, NavBar, ConfirmationDialog, DataGrid, Icon, CardHeader } from "../../shared/components";
import * as api from "../../shared/api-client";
import * as _ from "lodash";
import EditUserDialog from "./EditUser";
import { connect, useSelector } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { RootState } from "../../store";

function Users(props) {
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showEdit, setShowEdit] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState(null);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const isDeveloper = useSelector((root: RootState) => root.app.user.isDeveloper);

    React.useEffect(() => {
        setIsLoading(true);
        new api.UsersClient().search(undefined, isDeveloper)
            .then(result => setData(result))
            .finally(() => setIsLoading(false));
    }, [refreshCounter]);

    const onUserCreatedOrUpdated = () => {
        setRefreshCounter(refreshCounter + 1);
    }

    const createOrEditUser = (model) => {
        setActiveItem(model);
        setShowEdit(true);
    }

    const deleteUser = (model) => {
        setActiveItem(model);
        setConfirmOpen(true);
    }

    const onDeleteConfirmed = () => {
        new api.UsersClient().delete(activeItem.id)
            .then(() => {
                props.showSuccess("User deleted successfully.");
                setRefreshCounter(refreshCounter + 1);
            })
            .catch(error => {
                props.showError("Unable to delete user.  Please try again.");
            });
    }

    return (
        <>
            <NavBar
                title="Manage Users">
            </NavBar>

            <Container className="plush users-list" maxWidth="lg">
                <Card className="col-fill">
                    <CardHeader title="User List" action={
                        <Button className="align-self-center" size="small" variant="contained" color="success" onClick={() => createOrEditUser(null)}>
                            <Icon fontSize="small">add</Icon>
                            Add User
                        </Button>
                    } />
                    <DataGrid
                        disableSelectionOnClick
                        loading={isLoading}
                        columns={[
                            { headerName: 'Name', field: 'name', flex: 1 },
                            { headerName: 'Email', field: 'emailAddress', flex: 1 },
                            { headerName: 'Roles', field: 'roles', flex: 1, renderCell: (e) => _.join(_.map(e.row.roles, r => r.name), ", ") },
                            {
                                field: "actions", width: 150, type: "actions", getActions: (r) => [
                                    <Button size="small" variant="contained" color="primary" onClick={(e) => { createOrEditUser(r.row); e.preventDefault(); }} title="Edit User"><Icon fontSize="small">edit</Icon></Button>,
                                    <Button size="small" variant="contained" color="error" onClick={(e) => { deleteUser(r.row); e.preventDefault(); }} title="Delete User"><Icon fontSize="small">delete</Icon></Button>
                                ]
                            }
                        ]}
                        rows={data}
                        initialState={{
                            sorting: {
                                sortModel: [
                                    { field: 'name', sort: 'asc' }
                                ]
                            }
                        }}
                    />
                </Card>
            </Container>

            <EditUserDialog key={activeItem?.id} open={showEdit} setOpen={setShowEdit} user={activeItem} onSuccess={onUserCreatedOrUpdated} />

            <ConfirmationDialog
                title="Delete User?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={onDeleteConfirmed}
            >
                {`Are you sure you want to delete ${activeItem?.name}?`}
            </ConfirmationDialog>

        </>
    );
}

export default connect(
    null,
    { showSuccess, showError })(Users);