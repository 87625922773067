import { BrowserHistory } from "history";
import _ from "lodash";
import moment from "moment";


export function convertStringToValue(valueString: string) {
    // If value is prefixed with \, strip out the escape character and return the value as-is...
    if (valueString?.startsWith?.("\\")) return valueString.substring(1);

    // Check if value is a number...
    const number = Number(valueString);
    if (!isNaN(number)) return number;

    // Check if value is a date...
    const date = new Date(valueString);
    if (date instanceof Date && !isNaN(date as any)) return date;

    // Try to parse value using JSON deserializer...
    try {
        return JSON.parse(valueString);
    } catch { }

    // No matches - assume the value is a plain string...
    return valueString;
}

function convertAbbreviatedNumberToText(_, negative: string, currencyCode: string, number: number, uom: "k" | "m" | "b") {
    const uoms = {
        "k": " thousand",
        "m": " million",
        "b": " billion"
    };

    const currencies = {
        "R": " rand",
        "$": " dollar",
        "%": " percent"
    };

    return `${negative ? "minus " : ""}${number}${uoms[uom] || ""}${currencies[currencyCode] || ""}`;
}

export function sanitizeTextForNarration(text: string | string[]) {
    let result = (text instanceof Array) ? text.join(". ") : text; // Concatenate sentences together or use the single sentence...
    result = result.replace(/<[^>]+>/g, ''); // Remove all HTML markup...
    result = result.replace(/(-)*\s*(R|$)*\s*([0-9.0-9]+\s*)(k|m|b)?/g, convertAbbreviatedNumberToText); // Convert abbreviated numbers/percentages to their reader-friendly counterparts...
    return result;
}

/// Higher-order wrapper around Intl.NumberFormat object that allows us to use it within template bindings...
export function getColumnNumberFormatter(decimalPlaces = 2, prefix = "", suffix = "", multiplicationFactor = 1) {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    } as Intl.NumberFormatOptions);

    return (params: { value: number }) => {
        if (params?.value === null || params?.value === undefined) return "";

        return `${prefix}${formatter.format((params?.value || 0) * multiplicationFactor)}${suffix}`;
    }
}

export function formatNumber(value: number, decimalPlaces = 2, prefix = "", suffix = "", multiplicationFactor = 1) {
    return getColumnNumberFormatter(decimalPlaces, prefix, suffix, multiplicationFactor)({ value });
}

/// Higher-order wrapper around moment.format that allows us to use it within template bindings...
export function getColumnDateTimeFormatter(formatString = "YYYY-MM-DD HH:mm") {
    return (params: { value: string | Date | moment.Moment }) => params?.value ? moment(params?.value).format(formatString) : "";
}

export function formatDate(value: string | Date | moment.Moment, formatString = "YYYY-MM-DD HH:mm") {
    return getColumnDateTimeFormatter(formatString)({ value });
}

let measuringCanvas: HTMLCanvasElement = null;

/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  * 
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  * 
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
export function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = measuringCanvas || (measuringCanvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

export function describeArc(x, y, radius, startAngle, endAngle) {
    endAngle = Math.min(endAngle, 359.9999);
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
        "L", x, y,
        "L", start.x, start.y
    ].join(" ");

    return d;
}

export function shouldRecordTraces() : boolean {
    return window["SHOULD_RECORD_TRACES"] ?? (() => {
        try {
            const lastValue = localStorage.getItem("SHOULD_RECORD_TRACES") === "1";
            return window["SHOULD_RECORD_TRACES"] = lastValue ?? false;
        }
        catch {
            // User has disabled local storage or there was an issue fetching value - default to false...
            return window["SHOULD_RECORD_TRACES"] = false;
        }
    })();
}

export function setShouldRecordTraces(newValue: boolean) {
    localStorage.setItem("SHOULD_RECORD_TRACES", newValue ? "1" : "0");
    window["SHOULD_RECORD_TRACES"] = newValue;
}

export function getHashCode(s: string) {
    return s.split("").reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a;
    }, 0);
}

export class MapWithPathSupport<TValue> extends Map<string, TValue> {
    get(key: string) {
        // If the key is not a string, don't attempt to navigate the path...
        if (typeof key !== "string") {
            return super.get(key);
        }

        // Split the (possibly nested) path into its individual keys...
        const keys = MapWithPathSupport.getPathKeys(key);

        // Get the initial value...
        let currentValue = super.get(keys[0]);

        // Navigate the rest of the keys, using the current value as the basis...
        for (let i = 1; i < keys.length; i++) {
            // Short-circuit once we reach null/undefined...
            if (currentValue === null || currentValue === undefined) {
                return currentValue;
            }

            // Take the next step...
            const fragment = keys[i];
            currentValue = currentValue?.[fragment];
        }

        return currentValue;
    }

    has(key: string) {
        return this.get(key) !== undefined;
    }

    set(key: string, newValue: TValue) {
        // If the key is not a string, don't attempt to navigate the path...
        if (typeof key !== "string") {
            return super.set(key, newValue);
        }

        // Split the (possibly nested) path into its individual keys...
        const keys = MapWithPathSupport.getPathKeys(key);

        // Don't get fancy if the path is just a single key...
        if (keys.length === 1) {
            return super.set(key, newValue);
        }

        // Grab the root object and create an object containing the full path...
        const baseKey = keys[0];
        const existingObject = super.get(baseKey);
        let pathedObject = { [baseKey]: _.cloneDeep(existingObject) };
        
        // Set the nested value, creating any missing objects along the way...
        _.set(pathedObject, key, newValue);

        // Update the dictionary value with the updated one...
        return super.set(baseKey, pathedObject[baseKey]);
    }

    static getPathKeys(path: string) {
        // Split the (possibly nested) path into its individual keys...
        return path
            .split(/[.[\]]/)
            .filter(Boolean) // Remove null/empty values
            .map(k => k.replace(/['"]/g, "")); // Remove quotes around keys
    }

    static getBaseParameterName(key: string) {
        return MapWithPathSupport.getPathKeys(key)?.[0];
    }
}

function getUrl(path: string, parameters: Record<string, any>, obfuscateParameters: boolean = false): URL {
    const base = path.startsWith("/") ? window.location.origin : undefined;
    const result = new URL(path, base);

    Object.entries(parameters).forEach(([key, value]) => {
        if (value === null || value === undefined) {
            return;
        } else if (_.isDate(value) || moment.isMoment(value)) {
            result.searchParams.append(key, value.toISOString());
        } else if (_.isObject(value)) {
            result.searchParams.append(key, JSON.stringify(value));
        } else if (_.isString(value)) {
            // Prefix string values with a backslash to prevent them from being interpreted as other data types when parsed...
            result.searchParams.append(key, value.startsWith("\\") ? value : `\\${value}`);
        } else {
            result.searchParams.append(key, value?.toString ? value.toString() : JSON.stringify(value));
        }
    });

    // Replace the parameter values with a single obfuscated parameter...
    if (obfuscateParameters) {
        const obfuscatedParameters = btoa(result.search);
        const obfuscatedHash = getHashCode(obfuscatedParameters);

        const obfuscatedResult = new URL(path, base);
        obfuscatedResult.searchParams.append("_parameters", obfuscatedParameters);
        obfuscatedResult.searchParams.append("_hash", obfuscatedHash.toString());

        return obfuscatedResult;
    }
    
    return result;
}

export function getNavigateToPageHandler(parameterValues: Record<string, any>, navFiltersParameterName: string, browserHistory: BrowserHistory) {
    return (targetUrl: string, additionalParameters: Record<string, any> = {}, openNewTab: boolean = false, obfuscateParameters: boolean = false, includeNavFilterValues: boolean = true) => {
        const getNavFilterParameters = () => {
            if (!includeNavFilterValues) return {};

            const navFilterParameterNames = new Set(parameterValues?.[navFiltersParameterName] ?? []);
            return Object.fromEntries(Object.entries(parameterValues ?? {}).filter(([key, _value]) => navFilterParameterNames.has(key))) ?? {};
        };

        let url = getUrl(targetUrl, { ...getNavFilterParameters(), ...additionalParameters }, obfuscateParameters);

        if (openNewTab) {
            window.open(url, "_blank");
        } else {
            browserHistory.push({
                pathname: url.pathname,
                search: url.search
            });
        }
    }
};

// Custom comparison function to exclude functions and DOM elements from the equality checks...
function excludeTransientEntries(value) {
    if (_.isFunction(value) || _.isElement(value) || _.isSymbol(value) || _.has(value, "$$typeof")) {
        return true;
    }
};
export function structuralEqualityExcludingTransientEntries(valueOne, valueTwo) {
    return _.isEqualWith(valueOne, valueTwo, excludeTransientEntries);
};