import React from "react";
import "./App.scss";
import { unstable_HistoryRouter as HistoryRouter, Routes, Route, useLocation } from "react-router-dom";
import history from "../shared/history";
import { connect, useDispatch } from "react-redux";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { loadApp, setPageError } from "../store/app/actions";
import { incrementOutstandingRequestCount, decrementOutstandingRequestCount } from "../store/notifications/actions";
import { RootState } from "../store";
import { menuItems as defaultAdminMenuItems, default as Admin } from "../admin";
import Viewer from "../viewer";
import Public from "../public";
import LoginCallback from "../auth/LoginCallback";
import { fetchIntercept } from "../shared/monkey-patches";
import type { FetchInterceptor } from "../shared/services/fetchInterceptService";
import { useAuth0 } from "../auth/AuthContext";
import { SnackbarProvider } from 'notistack';
import Notifier from "../shared/components/Notifier";
import RequireAuthentication from "../auth/RequireAuthentication";
import Sidenav from "../shared/components/Sidenav";
import UserPreferences from "../shared/pages/UserPreferences";
import { AppThemeProvider } from "../shared/providers/AppThemeProvider";
import { ThunkDispatch } from "redux-thunk";
import { MenuItemDisplayModel, MenuLocation } from "../shared/api-client";
import RequiresRole from "../shared/components/RequiresRole";
import { ROLES } from "../auth/types";
import { useSettings } from "../shared/providers/SettingsProvider";
import { ErrorContainer } from "../shared/pages/ErrorContainer";
import type { AppErrors } from "../store/app/types";

LicenseInfo.setLicenseKey(
    "c0999568c3def75091e9f9d50d778258T1JERVI6MjczNTksRVhQSVJZPTE2NTg0NzIyOTgwMDAsS0VZVkVSU0lPTj0x"
);

interface AppProps {
    menuItems: MenuItemDisplayModel[],
    errors: AppErrors,
    isLoading: boolean,
    loadApp: typeof loadApp,
    incrementOutstandingRequestCount: typeof incrementOutstandingRequestCount,
    decrementOutstandingRequestCount: typeof decrementOutstandingRequestCount
}

function App(props: AppProps) {
    const { menuItems, errors, isLoading, loadApp, incrementOutstandingRequestCount, decrementOutstandingRequestCount } = props;
    const { isAuthenticated, getTokenSilently, roles, user } = useAuth0();
    const [isInitialized, setIsInitialized] = React.useState(false);
    const dispatch = useDispatch() as ThunkDispatch<any, unknown, any>;
    const settings = useSettings();

    React.useEffect(() => {
        if (!isAuthenticated) return;

        // Register an HTTP interceptor that adds the latest JWT token to the request headers and tracks outstanding AJAX requests...
        const unregister = fetchIntercept.register({
            request: async (url, config) => {
                if (url?.startsWith?.(window.location.origin) || url?.startsWith?.("/")) {
                    incrementOutstandingRequestCount();

                    if (isAuthenticated && config != null) {
                        const token = await getTokenSilently();
                        config.headers["Authorization"] = `Bearer ${token}`;
                    }
                }

                return [url, config];
            },
            requestError: function (error) {
                decrementOutstandingRequestCount();

                // Called when an error occured during another 'request' interceptor call
                return Promise.reject(error);
            },
            response: function (response) {
                if (response?.url?.startsWith?.(window.location.origin) || response?.url?.startsWith?.("/")) {
                    decrementOutstandingRequestCount();

                    // Log failed requests to console.  App Insights collects all failed network requests, so this is purely for the benefit of the app developer.
                    if (response.status >= 400) {
                        console.error("API Request Failed:", response);
                    }
                }

                // Modify the reponse object
                return response;
            },
            responseError: function (error) {
                decrementOutstandingRequestCount();

                // Log failed requests to console.  App Insights collects all failed network requests, so this is purely for the benefit of the app developer.
                console.error("API Request Failed:", error);

                // Handles fetch error...
                return Promise.reject(error);
            }
        } as FetchInterceptor);

        loadApp({
            name: user.name,
            roles: roles,
            isDeveloper: roles.includes(ROLES.DEVELOPER),
        }); // Load the storylines and canvases for the current tenant once-off...

        setIsInitialized(true);

        return unregister;
    }, [isAuthenticated]);

    const { storylineMenuItems, adminMenuItems } = React.useMemo(() => {
        return {
            storylineMenuItems: menuItems.filter(mi => mi.menuLocationId == MenuLocation.Storyline),
            adminMenuItems: [
                ...defaultAdminMenuItems,
                ...menuItems
                    .filter(mi => mi.menuLocationId == MenuLocation.Admin)
                    .map(mi => ({ ...mi, url: (mi.url.startsWith("/") && !mi.url.startsWith("/admin")) ? `/admin${mi.url}` : mi.url }))
            ]
        };
    }, [menuItems]);

    return (
        <AppThemeProvider>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                maxSnack={5}
            >
                <Notifier />
                <ErrorContainer error={errors.global}>
                    <HistoryRouter history={history} >
                        <RouteChangeMonitor>
                            <Route path="login-callback" element={<LoginCallback />} />
                            <Route path="preferences" element={
                                <RequireAuthentication isInitialized={isInitialized && !isLoading}>
                                    <div className="app-layout preferences-page">
                                        <Sidenav menuItems={storylineMenuItems} />
                                        <div className="content">
                                            <UserPreferences />
                                        </div>
                                    </div>
                                </RequireAuthentication>
                            } />
                            <Route path="public/*" element={<Public />} />
                            <Route path="admin/*" element={
                                <RequireAuthentication isInitialized={isInitialized && !isLoading}>
                                    <RequiresRole roleName={ROLES.ADMINISTRATOR}>
                                        <Admin menuItems={adminMenuItems} />
                                    </RequiresRole>
                                </RequireAuthentication>
                            } />
                            <Route path="/*" element={
                                <RequireAuthentication isInitialized={isInitialized && !isLoading}>
                                    <Viewer menuItems={storylineMenuItems} />
                                </RequireAuthentication>
                            } />
                        </RouteChangeMonitor>
                    </HistoryRouter>
                </ErrorContainer>
            </SnackbarProvider>
        </AppThemeProvider>
    );
}

const RouteChangeMonitor = (props) => {
    const { children } = props;
    const location = useLocation();
    const dispatch = useDispatch() as ThunkDispatch<any, unknown, any>;

    // Reset the page error state when the user navigates to a new page...
    React.useEffect(() => {
        dispatch(setPageError(null));
    }, [location]);


    return (
        <Routes>
            {children}
        </Routes>
    );
}

export default connect(
    (state: RootState) => ({
        menuItems: state.app.menuItems,
        errors: state.app.errors,
        isLoading: state.app.loading,
    }),
    { incrementOutstandingRequestCount, decrementOutstandingRequestCount, loadApp: loadApp as any })(App);