import * as api from "../../shared/api-client";
import {
    StorylineState,
    StorylineActionTypes,
    Slide,
    Frame,
    LOAD_STORYLINE,
    SHOW_STORYLINE,
    SHOW_CANVAS_AS_STORYLINE,
    GO_TO_X_Y_Z,
    GO_TO_ID,
    PARAMETER_VALUE_UPDATED,
    DATASOURCE_UPDATED,
    UPDATE_CURRENT_SLIDE_TEMPLATE,
    UPDATE_CURRENT_FRAME_DATA,
    UPDATE_CANVAS_STATE,
    ADD_GLOBAL_DATASOURCE,
    UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE
} from "./types";
import * as _ from "lodash";
import { MapWithPathSupport } from "../../shared/utilities";

const initialState: StorylineState = {
    id: "",
    name: "",
    slides: [],
    datasources: new Map<string, api.DatasourceDisplayModel>(),
    datasourceValues: new Map<string, any[]>(),
    datasourcesInFlight: new Set<string>(),
    parameterValues: new MapWithPathSupport<any>(),
    xIndex: 0,
    yIndex: 0,
    previousFrameIndex: 0,
    frameIndex: 0,
    currentFrame: undefined,
    loading: true,
    canNavigateBackwards: false,
    canNavigateForward: false,
    canvasState: {}
}

export function storylineReducer(
    state = initialState,
    action: StorylineActionTypes
): StorylineState {
    switch (action.type) {
        case LOAD_STORYLINE:
            return {
                ...initialState,
                id: action.id
            };

        case SHOW_STORYLINE:
            const slides = _.map(action.storyline.canvases, rootCanvas => {
                let verticalSlice = [rootCanvas, ...rootCanvas.children];

                return _.map(verticalSlice, slide => {
                    const canvasDataChunks = _.map(slide.canvas.datasources, ds => action.canvasData.get(ds.id));
                    // Combine the frame data properties from all the different data sources...
                    const canvasData = _.reduce(canvasDataChunks, (acc, elem) => {
                        return _.chain(_.zip(acc, elem)).map(a => _.merge({}, a[0], a[1])).value();
                    });

                    return ({
                        id: slide.id,
                        canvasId: slide.canvas.id,
                        name: slide.canvas.name,
                        chapterTitle: rootCanvas.chapterTitle,
                        pageTitle: slide.pageTitle,
                        canHideChapter: rootCanvas.canHideChapter,
                        canHidePage: slide.canHidePage,
                        template: slide.canvas.template,
                        datasourceIds: _.map(slide.canvas.datasources, ds => ds.id),
                        frames: canvasData
                    } as Slide)
                });
            });

            let storylineDatasources = _.chain(action.storyline.canvases)
                .flatMap(canvas => [...canvas.canvas.datasources, ..._.flatMap(canvas.children, child => child.canvas.datasources)])
                .uniq()
                .map(ds => [ds.id, ds] as any)
                .value();

            return {
                id: action.id,
                name: action.storyline.name,
                slides,
                datasources: new Map(storylineDatasources),
                datasourceValues: action.canvasData,
                datasourcesInFlight: state.datasourcesInFlight,
                parameterValues: action.parameterValues,
                xIndex: 0,
                yIndex: 0,
                frameIndex: 0,
                currentFrame: getFrame(slides, 0, 0, 0),
                loading: false,
                canNavigateBackwards: state.xIndex > 0 || state.yIndex > 0 || state.frameIndex > 0,
                canNavigateForward: slides?.length > (state.xIndex + 1) || slides?.[0]?.length > (state.yIndex + 1) || slides?.[0]?.[0]?.frames?.length > (state.frameIndex + 1),
                canvasState: {}
            };

        case SHOW_CANVAS_AS_STORYLINE:
            const canvasDataChunks = _.map(action.canvas.datasources, ds => action.canvasData.get(ds.id));
            // Combine the frame data properties from all the different data sources...
            const canvasData = _.reduce(canvasDataChunks, (acc, elem) => {
                return _.chain(_.zip(acc, elem)).map(a => _.merge({}, a[0], a[1])).value();
            });

            const slide = {
                canvasId: action.canvas.id,
                name: action.canvas.name,
                canHideChapter: true,
                canHidePage: true,
                template: action.canvas.template,
                datasourceIds: _.map(action.canvas.datasources, ds => ds.id),
                frames: canvasData
            } as Slide;

            let canvasDatasources = _.map(action.canvas.datasources, ds => [ds.id, ds] as any);

            return {
                id: action.id,
                name: action.canvas.name,
                slides: [[slide]],
                datasources: new Map(canvasDatasources),
                datasourceValues: action.canvasData,
                datasourcesInFlight: state.datasourcesInFlight,
                parameterValues: action.parameterValues,
                xIndex: 0,
                yIndex: 0,
                frameIndex: 0,
                currentFrame: getFrame([[slide]], 0, 0, 0),
                loading: false,
                canNavigateBackwards: state.xIndex > 0 || state.yIndex > 0 || state.frameIndex > 0,
                canNavigateForward: slide.frames?.length > (state.frameIndex + 1),
                canvasState: {}
            };

        case PARAMETER_VALUE_UPDATED:
            state.parameterValues.set(action.parameterName, action.newValue);
            return {
                ...state,
                parameterValues: new MapWithPathSupport(state.parameterValues)
            };

        case DATASOURCE_UPDATED:
            state.datasourceValues.set(action.datasourceId, action.data);

            // Get all the canvases affected by this change and recalculate their frames property...
            const newSlides = _.map(state.slides, verticalSlice => {
                return _.map(verticalSlice, slide => {
                    const canvasDataChunks = _.map(slide.datasourceIds, dsId => state.datasourceValues.get(dsId));
                    // Combine the frame data properties from all the different data sources...
                    const canvasData = _.reduce(canvasDataChunks, (acc, elem) => {
                        return _.chain(_.zip(acc, elem)).map(a => _.merge({}, a[0], a[1])).value();
                    });

                    return ({
                        ...slide,
                        frames: canvasData
                    } as Slide);
                });
            });

            return {
                ...state,
                slides: newSlides,
                datasourceValues: state.datasourceValues,
                currentFrame: getFrame(newSlides, state.xIndex, state.yIndex, state.frameIndex, state.previousFrameIndex),
                canNavigateBackwards: state.xIndex > 0 || state.yIndex > 0 || state.frameIndex > 0,
                canNavigateForward: state.slides?.length > (state.xIndex + 1) || state.slides?.[state.xIndex]?.length > (state.yIndex + 1) || state.slides?.[state.xIndex]?.[state.yIndex]?.frames?.length > (state.frameIndex + 1)
            };

        case GO_TO_X_Y_Z:
            state.xIndex = action.xIndex;
            state.yIndex = action.yIndex;
            state.previousFrameIndex = action.previousFrameIndex;
            state.frameIndex = action.frameIndex;

            return {
                ...state,
                currentFrame: getFrame(state.slides, state.xIndex, state.yIndex, state.frameIndex, state.previousFrameIndex),
                canNavigateBackwards: state.xIndex > 0 || state.yIndex > 0 || state.frameIndex > 0,
                canNavigateForward: state.slides?.length > (state.xIndex + 1) || state.slides?.[state.xIndex]?.length > (state.yIndex + 1) || state.slides?.[state.xIndex]?.[state.yIndex]?.frames?.length > (state.frameIndex + 1)
            };

        case GO_TO_ID:
            // Try to find the coordinates of the slide with the provided name, id or canvas id...
            const matchingCoordinates = _.chain(state.slides)
                .flatMap((x, xi) => _.map(x, (y, yi) =>
                    y?.name?.toUpperCase() === action.id?.toUpperCase() ||
                        y?.id?.toUpperCase() === action.id?.toUpperCase() ||
                        y?.canvasId?.toUpperCase() === action.id?.toUpperCase() ?
                        [xi, yi] :
                        null))
                .filter(a => !!a)
                .first()
                .value();

            if (matchingCoordinates) {
                const [xi, yi] = matchingCoordinates;
                state.xIndex = xi;
                state.yIndex = yi;
                state.previousFrameIndex = null;
                state.frameIndex = 0;
            }
            else {
                console.log(`No slide with Name, ID or Canvas ID = "${action.id}" found.`);
            }

            return {
                ...state,
                currentFrame: getFrame(state.slides, state.xIndex, state.yIndex, state.frameIndex, state.previousFrameIndex),
                canNavigateBackwards: state.xIndex > 0 || state.yIndex > 0 || state.frameIndex > 0,
                canNavigateForward: state.slides?.length > (state.xIndex + 1) || state.slides?.[state.xIndex]?.length > (state.yIndex + 1) || state.slides?.[state.xIndex]?.[state.yIndex]?.frames?.length > (state.frameIndex + 1)
            };

        case UPDATE_CURRENT_SLIDE_TEMPLATE:
            state.currentFrame.template.contents = action.newTemplate;
            state.currentFrame.template.customCss = action.newCustomCss;

            return {
                ...state,
                currentFrame: {
                    ...state.currentFrame,
                    template: {
                        ...state.currentFrame.template,
                        contents: action.newTemplate ?? state.currentFrame.template.contents,
                        customCss: action.newCustomCss ?? state.currentFrame.template.customCss
                    }
                }
            };

        case UPDATE_CURRENT_FRAME_DATA:
            return {
                ...state,
                currentFrame: {
                    ...state.currentFrame,
                    frame: action.newData
                }
            }

        case UPDATE_CANVAS_STATE:
            return {
                ...state,
                canvasState: action.newState
            }

        case ADD_GLOBAL_DATASOURCE:
            return {
                ...state,
                datasources: state.datasources.set(action.datasource.id, action.datasource),
                slides: state.slides.map(s1 => s1.map(s2 => ({...s2, datasourceIds: [...s2.datasourceIds, action.datasource.id]})))
            }

        case UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE:
            const datasourceName = action.datasource.name;

            // New in-flight request...
            if (action.inFlightRequestCount > 0 && !state.datasourcesInFlight.has(datasourceName)) {
                return {
                    ...state,
                    datasourcesInFlight: new Set<string>([...state.datasourcesInFlight.values(), datasourceName])
                };
            }

            // In-flight request completed...
            if (action.inFlightRequestCount === 0 && state.datasourcesInFlight.has(datasourceName)) {
                return {
                    ...state,
                    datasourcesInFlight: new Set<string>([...state.datasourcesInFlight.values()].filter(ds => ds !== datasourceName))
                }
            }

            // No material change...
            return state;

        default:
            return state;
    }
}

function getFrame(slides: Slide[][], xIndex: number, yIndex: number, frameIndex: number, previousFrameIndex?: number) {

    if (!(slides && slides.length > xIndex && slides[xIndex].length > yIndex)) return {
        name: "",
        template: {
            id: "",
            name: "",
            contents: "",
            customCss: ""
        },
        frame: {}
    };

    let slide = slides[xIndex][yIndex];

    return ({
        name: slide.name,
        template: slide.template,
        frame: augmentFrameDataWithPreviousValues(slide.frames?.[frameIndex], slide.frames?.[previousFrameIndex != null ? previousFrameIndex : frameIndex])
    } as Frame);
}

function augmentFrameDataWithPreviousValues(newFrameData: any, oldFrameData: any) {
    let result = newFrameData;

    // VDT Previous Progress Bar values...
    if (oldFrameData?.vdts && oldFrameData?.vdts?.length === result?.vdts?.length) {
        for (let i = 0; i < result.vdts.length; i++) {
            augmentVdtNodeData(result.vdts[i]?.root, oldFrameData.vdts[i]?.root);
        }
    }

    return result;
}

function augmentVdtNodeData(newNode: any, oldNode: any) {
    if (!newNode || !oldNode) return;

    newNode.previousProgressBarValue = oldNode.progressBarValue;

    if (oldNode?.nodes?.length === newNode?.nodes?.length) {
        for (let i = 0; i < newNode?.nodes?.length; i++) {
            augmentVdtNodeData(newNode?.nodes?.[i], oldNode?.nodes?.[i]);
        }
    }
}