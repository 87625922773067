import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App/App";
import * as api from "./shared/api-client"
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./store";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { B2CAuthProvider } from "./auth/providers/B2CAuthProvider";
import { AnonymousAuthProvider } from "./auth/providers/AnonymousAuthProvider";
import history from "./shared/history";
import { SettingsProvider } from "./shared/providers/SettingsProvider";
import { init as initLoggingService } from "./shared/services/loggingService";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import "./shared/monkey-patches";
import { ErrorContainer } from "./shared/pages/ErrorContainer";
import { AppError } from "./store/app/types";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

new api.SettingsClient().getClientSettings().then(settings => {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: settings.appInsightsInstrumentationKey,
            extensions: [reactPlugin],
            endpointUrl: "/proxy/logging", // Reverse-proxy to App Insights API endpoint to circumvent ad-blockers...
            extensionConfig: {
                [reactPlugin.identifier]: { history: history }
            }
        }
    });

    appInsights.loadAppInsights();

    // Add the tenant name as a property to each metric...
    appInsights.addTelemetryInitializer((telemetryItem) => {
        telemetryItem.data["tenantName"] = settings.tenantName;
    });

    initLoggingService(appInsights);

    // If the site allows anonymous public access, use the dummy auth provider instead...
    const AuthProvider = settings.allowAnonymousAuthentication ?
        AnonymousAuthProvider : B2CAuthProvider;

    const appComponent =
        <SettingsProvider settings={settings}>
            <AuthProvider
                clientId={settings.auth.clientId}
                domain={settings.auth.domain}
                instance={settings.auth.instance}
                tenantId={settings.auth.tenantId}
                redirectUri={settings.auth.redirectUri}
                apiVersion={settings.apiVersion}
                environment={settings.environment}
                onRedirectCallback={onRedirectCallback}
            >
                <Provider store={store}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <App />
                    </LocalizationProvider>
                </Provider>
            </AuthProvider>
        </SettingsProvider>;

    createRoot(document.getElementById("root")).render(appComponent);
}).catch(ex => {
    const hostName = window.location.hostname;

    const getError = (ex): AppError => {
        if (ex.status == null)
            return { type: "Connectivity", message: `Your browser cannot reach the Qerent Web Application.`, details: `Unable to contact the server at \`${hostName}\`.\n\nPlease ensure you are connected to your corporate network or VPN.\n\nIf the issue persists, please contact the site administrator.` };

        if (ex.status === 404)
            return { type: "NotFound", message: "Tenant not found.", details: `No tenant is configured for this domain (\`${hostName}\`).\n\nPlease check the address and try again.` };

        return { type: "Generic", message: "An error occurred while loading tenant settings.", details: ex.response };
    };

    createRoot(document.getElementById("root")).render(<ErrorContainer error={getError(ex)} />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
