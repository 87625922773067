import { Card, CardHeader, CardContent, Button } from "../shared/components";
import { useAuth0 } from "./AuthContext";
import history from "../shared/history";
import { useQueryParams } from "../shared/hooks";

export default function LoginCallback() {
    const { loginWithRedirect, error } = useAuth0();
    const query = useQueryParams();
    const originalPath = query.get("original-path");

    // Redirect to the original page after a prior unsuccessful login attempt, or the home page if we erroneously ended up here...
    if (!error) {
        setTimeout(() => {
            history.push(originalPath ?? window.location.origin);
        }, 100);
    }

    const accessDenied = error?.includes("not authorized");

    return error ?
        <Card className="screen-centered" style={{ maxWidth: "600px" }}>
            <CardHeader title={accessDenied ? "Access Denied" : "Login Failed"} />
            <CardContent className="text-align-center">
                {error}
                <Button variant="contained" color="primary" className="align-self-center margin-top-3" onClick={() => loginWithRedirect({ state: JSON.stringify({ targetUrl: originalPath ?? window.location.origin }) })}>Try Again</Button>
            </CardContent>
        </Card>
        :
        <Card className="screen-centered">
            <CardHeader title="Login succeeded" />
            <CardContent className="text-align-center" style={{ padding: "40px" }}>
                Redirecting - please wait...
            </CardContent>
        </Card>
}